'use strict'


export default {
    computed: {
        delivery_method_schema(){

        if(this.delivery_method_model.delivery_methods.length < 0){
            return {delivery_method_schema: {
                fields: [
                    {
                        type: 'hidden',
                        model: 'delivery_method',
                        featrued: true,
                        required: true,
                        values: () => {
                            return this.delivery_method_names;
                        },
                        placeholder: '',
                    },
                ]
            }}

        }else{
            return {delivery_method_schema: {
                fields: [
                    {
                        type: 'select',
                        label: '送付方法',
                        model: 'delivery_method',
                        featrued: true,
                        required: true,
                        values: () => {
                            return this.delivery_method_names;
                        },
                        placeholder: '',
                    },
                ]
            }}
        }

        }
    },
    data () {
        return {
            delivery_method_model: {
                delivery_method: "",
                delivery_methods: [],
            },
            rules: {
                name: [{
                required: true,
                trigger: 'blur'
                }],
            },
            formOptions: {
              validateAfterLoad: true,
              validateAfterChanged: true,
              validateAsync: true
            }
        }
    }
};
