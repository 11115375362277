'use strict'


export default {
    computed: {

    },
    data () {
        return {
            maker_model: {
                arrival_time: "",
                delivery: "",
                arrival_times: [],
                delivery_to: [],
                delivery_notice: ""
            },
            rules: {
                name: [{
                required: true,
                trigger: 'blur'
                }],
            },
            maker_schema: {
                fields: [
                    {
                        type: 'select',
                        label: '届け先区分',
                        model: 'delivery',
                        featrued: true,
                        required: true,
                        validator: "string",
                        values: () => {
                            return this.maker_model.delivery_to;
                        },
                        placeholder: '',
                    },
                    {
                        type: 'select',
                        label: '時間帯を選択してしてください',
                        model: 'arrival_time',
                        featrued: true,
                        required: true,
                        validator: "string",
                        values: () => {
                            return this.maker_model.arrival_times;
                        },
                        placeholder: '',
                    }

                ]
            },
            formOptions: {
              validateAfterLoad: true,
              validateAfterChanged: true,
              validateAsync: true
            }
        }
    }
};
