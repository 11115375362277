<template>
<div>
  <div id="sq-ccbox">
    <!--
      You should replace the action attribute of the form with the path of
      the URL you want to POST the nonce to (for example, "/process-card")
    -->
    <form id="nonce-form" novalidate action="https://asia-northeast1-clinicdx.cloudfunctions.net/helloWorld" method="post">
      <div class="errorbox">
        <div class="error" v-for="error in errors" :key=error.message>
          {{error}}
        </div>
      </div>
      <div id="card-tainer">
        <div class="cardfields card-number" :id="this.order.prescriptionId+'-sq-card-number'">カード番号</div>
        <div class="cardfields expiration-date" :id="this.order.prescriptionId+'-sq-expiration-date'">有効期限</div>
        <div class="cardfields cvv" :id="this.order.prescriptionId+'-sq-cvv'">セキュリティコード</div>
        <div class="cardfields postal-code" :id="this.order.prescriptionId+'-sq-postal-code'">郵便番号</div>
      </div>
      <input type="hidden" id="card-nonce" name="nonce">
    </form>
  </div>
  <v-btn 
    @click="requestCardNonce($event)" 
      rounded
      color="primary"
      dark
    >決済を実行</v-btn>
</div>
</template>

<script>

/* eslint-disable */
export default {
  name: "paymentForm",
  data: function() {
    return {
      errors: [],
      masterpass: false,
      applePay: false
    };
  },
  watch: {
    showPaymentForm: function() {
      if (!this.showPaymentForm) {
        return 1;
      }
      this.paymentForm.build();
    }
  },
  props: {
    showPaymentForm: Boolean,
    order: Object
  },
  mounted: function() {
    let locationId = "L1PTJE5KTX5FT";
    let applicationId = "sandbox-sq0idb-eZzKqT_Kk3O-BPqTO4l6cQ";
    let that = this;
    this.paymentForm = new SqPaymentForm({
      autoBuild: false,
      environment: "Sandbox",
      applicationId: applicationId,
      locationId: locationId,
      inputClass: "sq-input",
      // Initialize the payment form elements

      // Customize the CSS for SqPaymentForm iframe elements
      inputStyles: [
        {
          fontSize: ".9em"
        }
      ],

      // Initialize Apple Pay placeholder ID
      applePay: {
        elementId: that.order.prescriptionId + "-sq-apple-pay"
      },

      // Initialize Masterpass placeholder ID
      masterpass: {
        elementId: that.order.prescriptionId + "-sq-masterpass"
      },

      // Initialize the credit card placeholders
      cardNumber: {
        elementId: that.order.prescriptionId + "-sq-card-number",
        placeholder: "カード番号"
      },
      cvv: {
        elementId: that.order.prescriptionId + "-sq-cvv",
        placeholder: "CVV"
      },
      expirationDate: {
        elementId: that.order.prescriptionId + "-sq-expiration-date",
        placeholder: "有効期限（MM / YY）"
      },
      postalCode: {
        elementId: that.order.prescriptionId + "-sq-postal-code",
        placeholder: "郵便番号"
      },

      // SqPaymentForm callback functions
      callbacks: {
        /*
           * callback function: methodsSupported
           * Triggered when: the page is loaded.
           */
        methodsSupported: function(methods) {
          // Only show the button if Apple Pay for Web is enabled
          // Otherwise, display the wallet not enabled message.
          that.applePay = methods.applePay;
          that.masterpass = methods.masterpass;
        },

        /*
           * Digital Wallet related functions
           */
        createPaymentRequest: function() {
          var paymentRequestJson;
          /* ADD CODE TO SET/CREATE paymentRequestJson */
          return paymentRequestJson;
        },
        validateShippingContact: function(contact) {
          var validationErrorObj;
          /* ADD CODE TO SET validationErrorObj IF ERRORS ARE FOUND */
          return validationErrorObj;
        },

        /*
           * callback function: cardNonceResponseReceived
           * Triggered when: SqPaymentForm completes a card nonce request
           */
        cardNonceResponseReceived: function(errors, nonce, cardData) {
          if (errors) {
            errors.forEach(function(error) {
              that.errors.push(error.message);
            });
            return;
          }
          // Assign the nonce value to the hidden form field
          document.getElementById("card-nonce").value = nonce;

          // POST the nonce form to the payment processing page
          document.getElementById("nonce-form").submit();
        },
        /*
           * callback function: paymentFormLoaded
           * Triggered when: SqPaymentForm is fully loaded
           */
        paymentFormLoaded: function() {
          console.log("paymentFormLoaded");
          /* HANDLE AS DESIRED */
        }
      }
    });
  },
  methods: {
    requestCardNonce: function(event) {
      // Don't submit the form until SqPaymentForm returns with a nonce
      event.preventDefault();

      // Request a nonce from the SqPaymentForm object
      this.paymentForm.requestCardNonce();
    },
    // SqPaymentForm callback functions
    callbacks: {
        /*
        * callback function: cardNonceResponseReceived
        * Triggered when: SqPaymentForm completes a card nonce request
        */
        cardNonceResponseReceived: function (errors, nonce, cardData) {
        if (errors) {
            // Log errors from nonce generation to the browser developer console.
            console.error('Encountered errors:');
            errors.forEach(function (error) {
                console.error('  ' + error.message);
            });
            alert('正しく入力されていません。内容をご確認ください。');
            return;
        }

        document.getElementById('card-nonce').value = nonce;
        document.getElementById('nonce-form').submit();
        }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
.sq-input {
  border: 1px solid rgb(223, 223, 223);
  outline-offset: -2px;
  margin-bottom: 5px;
  display: inline-block;
  border: none;
  color: #32325d;
  line-height: 18px;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-size: 16px;
  height: 18px;
  -webkit-font-smoothing: antialiased;
}

.sq-input ::placeholder {
  color: #aab7c4;
  opacity: 0.5;
}

/* Define how SqPaymentForm iframes should look when they have focus */

/* Define how SqPaymentForm iframes should look when they contain invalid values */

.sq-input--error {
  outline: 3px auto rgb(255, 97, 97);
}

.errorbox {
  line-height: 14px;
  text-align: left;
}

.error {
  font-size: 10px;
  color: rgb(164, 0, 30);
  width: 45%;
  display: inline-block;

  margin-top: -10px;
  font-weight: 400;
}

/* Customize the "Pay with Credit Card" button */

.button-credit-card {
  min-width: 200px;
  min-height: 20px;
  padding: 0;
  margin: 5px;
  line-height: 20px;
  box-shadow: 2px 2px 1px rgb(200, 200, 200);
  background: rgb(255, 255, 255);
  border-radius: 5px;
  border: 1px solid rgb(200, 200, 200);
  font-weight: bold;
  cursor: pointer;
}

.card-number {
  width: 100%;
}

.modal .payButton {
  margin-left: 0px;
  position: absolute;
  bottom: 0px;
  width: 400px;
}

/* Customize the "{{Wallet}} not enabled" message */

.wallet-not-enabled {
  min-width: 200px;
  min-height: 40px;
  max-height: 64px;
  padding: 0;
  margin: 10px;
  line-height: 40px;
  background: #eee;
  border-radius: 5px;
  font-weight: lighter;
  font-style: italic;
  font-family: inherit;
  display: block;
}

/* Customize the Apple Pay on the Web button */

.button-apple-pay {
  min-width: 200px;
  min-height: 40px;
  max-height: 64px;
  padding: 0;
  margin: 10px;
  background-image: -webkit-named-image(apple-pay-logo-white);
  background-color: black;
  background-size: 100% 60%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-radius: 5px;
  cursor: pointer;
  display: none;
}

/* Customize the Masterpass button */

.button-masterpass {
  min-width: 200px;
  min-height: 40px;
  max-height: 40px;
  padding: 0;
  margin: 10px;
  background-image: url(https://static.masterpass.com/dyn/img/btn/global/mp_chk_btn_147x034px.svg);
  background-color: black;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-radius: 5px;
  border-color: rgb(255, 255, 255);
  cursor: pointer;
}

#sq-walletbox {
  text-align: center;
  vertical-align: top;
  font-weight: bold;
}

#sq-ccbox {
  margin: 5px;
  padding: 0px 10px;
  text-align: center;
  vertical-align: top;
  font-weight: bold;
}

.expiration-date,
.cvv,
.postal-code {
  width: 30%;
  display: inline-block;
}

#card-tainer {
  text-align: left;
  margin-top: 8px;
  background-color: white;
  height: 80px;
  padding: 10px 12px;
  border-radius: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  box-sizing: border-box;
}
</style>
