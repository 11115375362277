"use strict";

import i18n from "@/i18n";
export default {
  computed: {},
  data() {
    return {
      address_model: {
        name: "",
        address: "",
        postcode: "",
        email: "",
        cellnumber: "",
        status: "",
      },
      rules: {
        name: [
          {
            required: true,
            trigger: "blur",
          },
        ],
      },
      address_schema: {
        fields: [
          {
            type: "input",
            inputType: "text",
            readonly: true,
            label: i18n.tc("郵便番号"),
            required: true,
            model: "postcode",
            validator: "string",
          },
          {
            type: "input",
            inputType: "text",
            label: i18n.tc("ご住所"),
            model: "address",
            placeholder: i18n.tc("住所を入力してください"),
            required: true,
            validator: "string",
          },
          {
            type: "input",
            inputType: "text",
            label: i18n.tc("お宛名"),
            model: "name",
            placeholder: i18n.tc("宛名を入力してください"),
            required: true,
            validator: "string",
          },
          {
            type: "input",
            inputType: "text",
            label: i18n.tc("メールアドレス"),
            model: "email",
            placeholder: i18n.tc("メールアドレスを入力してください"),
            required: true,
            validator: "email",
          },
          {
            type: "input",
            inputType: "text",
            label: i18n.tc("携帯電話"),
            model: "cellnumber",
            placeholder: i18n.tc("携帯電話を入力してください"),
            featrued: true,
            required: true,
            validator: "string",
          },
        ],
      },
      formOptions: {
        validateAfterLoad: true,
        validateAfterChanged: true,
        validateAsync: true,
      },
    };
  },
};
